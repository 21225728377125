/* Global styles for masonry components */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: none;
  margin-bottom: 30px;
}
/* End of masonry styles */

/* CUSTOM STYLES */
.aboutGibsonsVideosWrapper {
  background-image: url("./images/about-gibsons-bg.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.iframeBg {
  /* white background color of rgba opacity .5 */
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
  border-radius: 0.15rem;
}

.ethicalProductionWrapper {
  background-color: #f5f5f3;
  margin: 0;
}

.historyWrapper {
  background-image: url("./images/delight-bg.jpg");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  min-height: 400px;
}

.distributorsWrapper {
  background-color: #f8f6f7;
}

.contactDetailsBox {
  min-height: 270px;
  background: #742624;
  margin-bottom: 10px;
  padding: 1px 1rem 10px;
  color: #ffffff;
}

.gCofMap {
  min-height: 270px;
  background: #fff;
  margin-bottom: 10px;
  padding: 1px 1rem 10px;
}

.gCofMap > iframe {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 15px;
}

.gbContact {
  /* height: 100vh; */
  background: url(./images/contact-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex; */
  padding: 4rem;
}

.contactWrapper {
  background-color: rgba(255, 255, 255, 0.979);
  border-radius: 0.5rem;
  color: #745857;
}



.activeCategory {
  color: #f8931d !important;
}

.modal-video {
  background-color: rgba(0, 0, 0, 0.98) !important; /* Adjust the last value to control the darkness */
}